export const colors = {
  white: '#ffffff',
  black: '#000000',
  accent: '#801e26',
  accent2: '#dd385b',
  lightGray: '#b3bfca',
};

export const branding = {
  color: colors.accent,
  fontWeight: '800',
};

export const breakpoint = '900px';
