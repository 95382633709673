import React from 'react';
import styled from 'styled-components';
import { breakpoint } from '../imports/variables';

const SocialWrapper = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  height: 35px;
  justify-content: space-around;
  position: absolute;
  right: 25px;
  text-transform: uppercase;
  width: 200px;
  a {
    color: #b3bfca;
    font-size: 0.8rem;
    font-weight: 700;
    text-decoration: none;
  }

  @media screen and (max-width: ${breakpoint}) {
    display: none;
  }
`;

const Social = () => {
  return (
    <SocialWrapper>
      <a href='https://www.instagram.com/thebrandonkelly' target='_blank'>
        instagram
      </a>
      <a
        href='https://www.linkedin.com/in/brandon-kelly-creative/'
        target='_blank'
      >
        linkedin
      </a>
    </SocialWrapper>
  );
};

export default Social;
