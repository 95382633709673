import React, { useState, useRef } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { useOnClickOutside } from '../imports/hooks';
import { colors, breakpoint } from '../imports/variables';

import NavBtn from './navBtn';
import resume from '../../static/BKelly_Resume.pdf';

const DrawerContainer = styled.div`
  width: 250px;
  position: fixed;
  transition: all 250ms ease-out;
  transform: translateX(0);
  z-index: 1000;
  &.open {
    transform: translateX(100%);
  }

  @media screen and (max-width: ${breakpoint}) {
    width: 0;
    transform: translateX(0);
    &.open {
      transform: translateY(calc(100vh - 100px));
    }
  }
`;

const Handle = styled.div`
  align-items: center;
  background: ${colors.black};
  box-shadow: 1px 0 30px 1px hsla(0, 0%, 0%, 0.3);
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: absolute;
  top: 0;
  width: 100px;
  transition: all 250ms ease-out;
  h1 {
    color: ${colors.white};
    font-family: 'Raleway', sans-serif;
    font-size: 3rem;
    font-weight: 800;
    margin-top: 20px;
    .link_brand {
      color: ${colors.white};
      text-decoration: none;
    }
  }

  @media screen and (max-width: ${breakpoint}) {
    width: 100vw;
    height: 100px;
    flex-direction: row;
    justify-content: space-between;
    h1 {
      margin-top: 0;
      margin-left: 30px;
    }
  }
`;

const Drawer = styled.div`
  background: ${colors.lightGray};
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: -250px;
  padding: 50px 0 0 0;
  position: relative;
  text-align: center;
  width: 270px;
  overflow: scroll;
  a {
    margin: 0 0 15px 0;
  }
  .link {
    color: #801e26;
    font-weight: 800;
    letter-spacing: 4px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.4rem;
    margin-bottom: 60px;
  }
  .link_alt {
    color: ${colors.accent2};
  }

  @media screen and (max-width: ${breakpoint}) {
    height: calc(100vh - 50px);
    left: 0;
    padding-top: 150px;
    top: -100vh;
    width: 103vw;
    a {
      font-size: 1rem !important;
      margin-bottom: 40px !important;
    }
  }
`;

const NavDrawer = () => {
  const [open, setOpen] = useState(false);
  const node = useRef(null!);
  useOnClickOutside(node, () => {
    setOpen(false);
  });

  return (
    <DrawerContainer ref={node} className={open ? 'open' : ''}>
      <Drawer>
        <Link className='link' to='/' onClick={() => setOpen(false)}>
          home
        </Link>
        <Link
          className='link link_alt'
          to='/projects'
          onClick={() => setOpen(false)}
        >
          current projects
        </Link>
        <Link className='link' to='/about' onClick={() => setOpen(false)}>
          about
        </Link>
        <Link className='link' to='/media' onClick={() => setOpen(false)}>
          media
        </Link>
        <Link className='link' to='/connect' onClick={() => setOpen(false)}>
          connect
        </Link>
        <a href={resume} target='blank' className='link'>
          Download Resume
        </a>
      </Drawer>
      <Handle>
        <h1>
          <Link className='link_brand' to='/'>
            b.
          </Link>
        </h1>
        <NavBtn handleClick={() => setOpen(!open)} open={open} />
      </Handle>
    </DrawerContainer>
  );
};

export default NavDrawer;
