import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import GlobalStyle from '../imports/globalStyle';
import NavDrawer from './navDrawer';
import Social from './social';
import { breakpoint } from '../imports/variables';

const PageContainer = styled.div`
  display: flex;
  height: 100vh;
  margin-left: 100px;
  width: calc(100% - 100px);
  main {
    width: 100%;
    @media screen and (max-width: ${breakpoint}) {
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: ${breakpoint}) {
    display: block;
    margin-left: 0;
    width: 100vw;
  }
`;

const duration = 0.5;
const variants = {
  initial: {
    opacity: 0,
    y: '-10%',
    display: 'flex',
  },
  enter: {
    opacity: 1,
    y: '0%',
    transition: {
      duration,
      delay: duration,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: { duration },
  },
};

interface Props extends ChildElementProps {
  location: {
    pathname: string;
  };
}

const Layout = ({ location, children }: Props) => {
  return (
    <>
      <Helmet>
        <html lang='en' />
        <meta charSet='UTF-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <meta property='og:title' content='Brandon Kelly Creative' />
        <meta property='og:description' content='Prepare to b. Moved' />
        <meta property='og:image' content='https://i.imgur.com/Fay2dgd.png' />
        <meta
          property='og:url'
          content='https://www.brandonkellycreative.com'
        />
        <meta name='description' content='Brandon Kelly - Prepare to b Moved' />
        <meta httpEquiv='X-UA-Compatible' content='ie=edge' />
        <title>Brandon Kelly Creative</title>
        <link
          href='https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700;800&display=swap'
          rel='stylesheet'
        />
        <script src='https://player.vimeo.com/api/player.js'></script>
      </Helmet>
      <GlobalStyle />
      <NavDrawer />
      <AnimatePresence>
        <motion.main
          key={location.pathname}
          variants={variants}
          initial='initial'
          animate='enter'
          exit='exit'
        >
          <PageContainer>
            {children}
            <Social />
          </PageContainer>
        </motion.main>
      </AnimatePresence>
    </>
  );
};

export default Layout;
